declare var ccUrl: string;

module TimeForYou.AutoComplete
{
    const rateLimitDelay = 100;
    let ajax: XMLHttpRequest;
    let timeout: number;

    function initiateSearch(ev: KeyboardEvent)
    {
        if (typeof (timeout) !== "undefined")
        {
            clearTimeout(timeout);
        }

        if (typeof (ajax) !== "undefined" && ajax.readyState !== XMLHttpRequest.DONE)
        {
            ajax.abort();
        }

        timeout = window.setInterval(() => sendSearch(ev.target), rateLimitDelay);
    }

    function submitSearchFromKey(ev: KeyboardEvent)
    {
        if (ev.key === "Enter")
        {
            const widget = recurseUntilClass(ev.target as HTMLElement, "franchise-search-auto");
            if (!widget || !widget.parentElement)
            {
                return;
            }

            const button = widget.parentElement.getElementsByTagName("input")[1] as HTMLInputElement;
            button.click();
        }
    }

    function sendSearch(ctrl: EventTarget | null)
    {
        if (typeof (timeout) !== "undefined")
        {
            clearTimeout(timeout);
        }

        if (!ctrl)
        {
            return;
        }

        const inputCtrl = ctrl as HTMLInputElement;

        if (inputCtrl.value.length < 3)
        {
            const autoWrapper = inputCtrl.parentElement as HTMLDivElement;
            const oldItems = autoWrapper.getElementsByTagName("ul");

            for (let i = 0; i < oldItems.length; i++)
            {
                oldItems[i].remove();
            }
            return;
        }

        ajax = new XMLHttpRequest();
        ajax.addEventListener("readystatechange", () => completeSearch(ctrl));
        ajax.open("POST", `${ccUrl}/App_Services/SearchAutocomplete.asmx/AutoCompleteSearch`, true);
        ajax.setRequestHeader("accept", "application/json");
        ajax.setRequestHeader("content-type", "application/json; charset=UTF-8");
        ajax.send(JSON.stringify({
            query: inputCtrl.value
        }));
    }

    function completeSearch(ctrl: EventTarget)
    {
        if (ajax.readyState !== XMLHttpRequest.DONE || ajax.status !== 200)
        {
            return;
        }

        const items = JSON.parse(ajax.responseText).d as string[];


        const autoWrapper = (ctrl as HTMLInputElement).parentElement as HTMLDivElement;
        const oldItems = autoWrapper.getElementsByTagName("ul");

        for (let i = 0; i < oldItems.length; i++)
        {
            oldItems[i].remove();
        }

        if (items.length === 0)
        {
            return;
        }

        const autoList = document.createElement("ul") as HTMLUListElement;

        for (let i = 0; i < items.length; i++)
        {
            const listItem = document.createElement("li") as HTMLLIElement;
            listItem.innerText = items[i];
            listItem.addEventListener("click", selectSearch);
            autoList.appendChild(listItem);
        }

        autoWrapper.appendChild(autoList);
    }

    function selectSearch(ev: Event)
    {
        const item = ev.target as HTMLLIElement;
        const widget = recurseUntilClass(item, "franchise-search-auto");
        if (!widget || !widget.parentElement)
        {
            return;
        }
        const box = widget.getElementsByTagName("input")[0] as HTMLInputElement;

        box.value = item.innerText;

        const oldItems = widget.getElementsByTagName("ul");
        for (let i = 0; i < oldItems.length; i++)
        {
            oldItems[i].remove();
        }

        const button = widget.parentElement.getElementsByTagName("input")[1] as HTMLInputElement;
        button.click();
    }

    function recurseUntilClass(elem: HTMLElement | null, cssClass: string): HTMLElement | null
    {
        if (elem == null)
        {
            return null;
        }

        if (elem.classList.contains(cssClass))
        {
            return elem;
        }

        return recurseUntilClass(elem.parentElement, cssClass);
    }

    export function Initialise()
    {
        const searchWidgets = document.getElementsByClassName("franchise-search-auto");

        for (let i = 0; i < searchWidgets.length; i++)
        {
            const widget = searchWidgets[i] as HTMLDivElement;
            const box = widget.getElementsByTagName("input")[0] as HTMLInputElement;

            box.addEventListener("keyup", initiateSearch);
            box.addEventListener("keydown", submitSearchFromKey);
        }
    }
}

window.addEventListener("load", TimeForYou.AutoComplete.Initialise);